<template>
    <div id="content">
        <headerText></headerText>
        <!-- 第二部分 -->
        <div class="photo_img">
            <p>公司新闻</p>
        </div>
        <!-- 第三部分 新闻列表 -->
        <div class="newContent">
            <ul>
                <li v-for="(item,index) in newList" @click="goDetail(item.id)" :key="index">
                    <div class="newList clearfix">
                        <img class="fl" :src="item.imgUrl" alt="">
                        <div class="newRight fl">
                            <p class="newTitle fb">{{item.title}}</p>
                            <p class="new_text fs" v-for="(each,num) in item.articleSpecificList" :key="num">
                                {{each.content}}
                            </p>
                            <p class="new_time fs">{{item.createTime}}</p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <!-- 底部 -->
        <footerText></footerText>
    </div>
</template>

<script>
    import $ from 'jquery'
    export default {
        data() {
            return {
                newList: [],
                slide: true,
                pageNum: 1,
                pageSize: 99,
            };
        },
        created() {
            let _this = this;
            if (/Android|iPhone|iPod/i.test(navigator.userAgent)) {
                _this.$router.push('/companyNewsEn')
            }
            _this.getNewList();
            // window.addEventListener('scroll', function () {
            //     if ($(window).scrollTop() + $(window).height() >= $(document).height() - 300) {
            //         if (_this.slide) {
            //             _this.slide = false;
            //             _this.pageNum++;
            //             _this.getNewList();
            //         }
            //     }
            // });
        },
        methods: {
            getNewList() {
                let _this = this;
                $.ajax({
                    type: 'post',
                    url: _this.requestUrl + 'getArticleList',
                    data: {
                        pageNum: _this.pageNum,
                        pageSize: _this.pageSize,
                    },
                    dataType: 'json',
                    success: function (res) {
                        if (res.code == 1000) {
                            _this.newList = _this.newList.concat(res.data);
                            _this.sw = true;
                        }
                    }
                })
            },
            goDetail(id){
                this.$router.push({
                    path: '/newDetailsOne',
                    query: {
                        id: id
                    }
                })
            }
        }
    };
</script>

<style scoped lang="scss">
    @import '../../assets/css/companyNews.scss';
</style>
